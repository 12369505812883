import React from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Spinner,
  Text,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useUser } from '@clerk/nextjs';
import {
  useDashboardCRUD,
  useDashboardSWR,
  useToast,
  useUnsafeMetadata,
} from '@hooks';
import type { Application } from '@types';
import { InputBox } from '../common';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { Identifiers } from './Identifiers';
import { OAuthProviderList } from './OAuthProviderList';
import { Web3ProviderList } from './Web3ProviderList';
import { CreateAppIdentifier } from './types';
import { SUPPORTED_FEATURES } from '@constants';
import { isFeatureSupported } from '@utils/billing';
import { useAnalytics } from '@hooks/useAnalytics';

// TODO: Need to filter oauth providers by actually enabled ones on the server
// (i.e. those what have dev credentials set)
import { OAuthProvider, Web3Provider } from '@clerk/types';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { SignInPreview } from '@components/common';

import { useMediaQuery } from '@chakra-ui/react';

// TODO This should have dynamic field names too (or remove make them
type NewApplicationForm = {
  name: string;
  identifiers: {
    [Property in CreateAppIdentifier]?: boolean;
  };
  authentication_strategy: 'password_v2' | 'passwordless_v2';
  oauth: {
    [Property in OAuthProvider]?: boolean;
  };
  web3: {
    [Property in Web3Provider]?: boolean;
  };
};

const FORM_ID = 'new_application_form';

export const FORM_FIELDS_V2 = {
  name: 'name',
  identifiers: 'identifiers',
  authentication_strategy: 'authentication_strategy',
  authentication_strategy_options: {
    password: 'password_v2',
    passwordless: 'passwordless_v2',
  },
  oauth: 'oauth',
  web3: 'web3',
} as const;

export const DEFAULT_VALUES_V2: NewApplicationForm = {
  [FORM_FIELDS_V2.name]: '',
  [FORM_FIELDS_V2.identifiers]: { email_address: true },
  [FORM_FIELDS_V2.authentication_strategy]: 'password_v2',
  oauth: { google: true },
  web3: {},
} as const;

export const DEFAULT_APP_COLOR_V2 = '#6c47ff';

export function AddApplicationFormV2(): JSX.Element {
  const { track } = useAnalytics();
  const { user, isLoaded } = useUser();
  const { push, query } = useRouter();
  const { showErrorToast } = useToast();
  const { create } = useDashboardCRUD();
  const { addAppIdToSeeInfoModal } = useUnsafeMetadata();

  const { data: { oauth_providers, web3_providers, supported_features } = {} } =
    useDashboardSWR('/v1/system_config');
  const [isLargerThan991px] = useMediaQuery('(min-width: 1100px)');

  // Finalize the user identification in Hubspot after sign up. The following script
  // merges the backend user data sent from BAPI after user creation with frontend data
  // collected from the Hubspot tracking code. The user email us used to identify a user
  // uniquely.

  // More information can be found at:
  // https://developers.hubspot.com/docs/api/events/tracking-code
  React.useEffect(() => {
    if (isLoaded && query?.signed_up) {
      // @ts-ignore
      const _hsq = (window._hsq = window._hsq || []);

      _hsq.push([
        'identify',
        {
          email: user.primaryEmailAddress.emailAddress,
        },
      ]);

      _hsq.push(['trackPageView']);
    }
  }, [query, isLoaded, user]);

  React.useEffect(() => {
    void track('Dashboard_Applications_Application Configuration Form Viewed', {
      location: 'Applications',
      surface: 'Dashboard',
    });
  }, [track]);

  // `true` for the whole process to disable button
  const [isAddingApp, setIsAddingApp] = React.useState(false);

  const formMethods = useForm<NewApplicationForm>({
    defaultValues: DEFAULT_VALUES_V2,
    mode: 'onSubmit',
  });

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
  } = formMethods;

  const identifiers = useWatch({
    control,
    name: [
      FORM_FIELDS_V2.identifiers,
      FORM_FIELDS_V2.oauth,
      FORM_FIELDS_V2.web3,
    ],
  });

  const applicationName = useWatch({ control, name: 'name' });

  // we use identifiers[0] in order to get and compare FORM_FIELDS_V2.identifiers / first item in array
  const atLeastOneIdentifier = Object.entries({
    ...identifiers[0],
    ...identifiers[1],
    ...identifiers[2],
  }).some(([, value]) => value);

  const handleAddApp = async ({ name }): Promise<void> => {
    setIsAddingApp(true);

    try {
      const { identifiers, oauth, web3 } = getValues();

      const identifierList = Object.entries(identifiers).reduce(
        (memo, [name, enabled]) => {
          return enabled ? [...memo, name] : memo;
        },
        [],
      );

      const oauth_providers = Object.entries(oauth).reduce(
        (memo, [name, enabled]) => {
          return enabled ? [...memo, `oauth_${name}`] : memo;
        },
        [],
      );

      const web3_providers = Object.entries(web3).reduce(
        (memo, [name, enabled]) => {
          return enabled ? [...memo, `web3_${name}`] : memo;
        },
        [],
      );

      const app = (await create('/v1/applications', {
        name,
        color: DEFAULT_APP_COLOR_V2,
        identifiers: identifierList,
        authentication_strategy:
          identifierList.length > 0 ? 'password_v2' : 'passwordless_v2',
        oauth_providers,
        web3_providers,
      })) as Application;

      await addAppIdToSeeInfoModal(app.id);

      track('Dashboard_Applications_Application Configuration Form Submitted', {
        surface: 'Dashboard',
        location: 'Applications',
        applicationId: app.id,
        applicationName: app.name,
      });

      const devInstanceId = app.instances.find(
        i => i.environment_type === 'development',
      ).id;

      void push(`/apps/${app.id}/instances/${devInstanceId}?app_created=true`);
    } catch (error) {
      setIsAddingApp(false);
      showErrorToast('Something went wrong, please try again later.');
    }
  };

  if (!oauth_providers || !web3_providers) {
    return (
      <Flex h='xl' justify='center' align='center'>
        <Spinner />
      </Flex>
    );
  }

  const isSocialAndWeb3Unlimited =
    isFeatureSupported(
      supported_features,
      SUPPORTED_FEATURES.unlimited_social,
    ) &&
    isFeatureSupported(supported_features, SUPPORTED_FEATURES.unlimited_web3);

  const providersForPreview = () => {
    const providers = { ...identifiers[1], ...identifiers[2] };

    return Object.keys(providers).filter(providerKey =>
      Boolean(providers[providerKey]),
    );
  };

  const identifiersForPreview = Object.keys(identifiers[0]).filter(identifier =>
    Boolean(identifiers[0][identifier]),
  );

  return (
    <>
      <Flex
        justifyContent='center'
        maxHeight='100%'
        flex={1}
        position='absolute'
        top={0}
        left={0}
        right={0}
        bottom={0}
        mr='5.5rem'
        ml={8}
      >
        <Flex flexDirection='column' width='lg' mb={6}>
          <Box px={4} mb={4}>
            <Text fontSize='xl' fontWeight='600' mb={1}>
              Let&apos;s build your {'<SignIn />'}
            </Text>
          </Box>
          <FormProvider {...formMethods}>
            <Flex
              as='form'
              id={FORM_ID}
              onSubmit={handleSubmit(handleAddApp)}
              flexDirection='column'
              overflow='hidden'
            >
              <InputBox
                label='Application name'
                error={errors?.[FORM_FIELDS_V2.name]}
                mb={0}
              >
                <Input
                  {...register(FORM_FIELDS_V2.name, {
                    required: 'Application name is required',
                  })}
                  placeholder='My Application'
                  autoFocus
                />
              </InputBox>

              <Flex
                position='relative'
                overflow='hidden'
                p={4}
                flexDirection='column'
              >
                {!isSocialAndWeb3Unlimited && (
                  <Text mt={0} fontSize='sm' color='blackAlpha.600'>
                    Enable up to three providers. Upgrade plan after creating
                    application to enable more.
                  </Text>
                )}
                <Flex mb={3}>
                  <Text color='black' textStyle='md-medium'>
                    How will your users sign in?
                  </Text>
                  <Tooltip
                    label='Sign-in configurations can be modified later in the dashboard.'
                    placement='right'
                  >
                    <Icon
                      ml={2}
                      as={QuestionMarkCircleIcon}
                      boxSize={5}
                      color='blackAlpha.700'
                    />
                  </Tooltip>
                </Flex>

                <OAuthProviderList
                  supportedFeatures={supported_features}
                  oauthProviderStrategies={oauth_providers}
                  identifiers={
                    <Identifiers supportedFeatures={supported_features} />
                  }
                  web3Providers={
                    <Web3ProviderList
                      supportedFeatures={supported_features}
                      web3Providers={web3_providers}
                    />
                  }
                />
              </Flex>
            </Flex>
          </FormProvider>
          <Flex px={4} justifyContent='space-between'>
            <Button
              pl={4}
              type='button'
              variant='ghost'
              onClick={() => push('/')}
            >
              Cancel
            </Button>

            <Button
              form={FORM_ID}
              type='submit'
              isDisabled={!atLeastOneIdentifier}
              isLoading={isAddingApp}
            >
              Create application
            </Button>
          </Flex>
        </Flex>

        {isLargerThan991px && (
          <Flex ml={16} borderRadius='2xl' pb={6} maxHeight='100%' width='100%'>
            <SignInPreview
              applicationName={applicationName}
              identifiers={identifiersForPreview}
              socialProviders={providersForPreview()}
            />
          </Flex>
        )}
      </Flex>
    </>
  );
}
